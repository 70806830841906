<template>
    <Popup
        size="lg"
        custom-class="gray-header"
        :onHide="close"
        :on-close="close"
        :scrollable="true"
        :toggle-status="showPopup"
        :modal-id="`${Date.now()}-module-selector`"
    >
        <template v-slot:header>
            <div class="bundle-module-remark__header">
                {{ labels.select_module }}
            </div>
        </template>

        <template v-slot:body>
            <SvgLoader v-if="!dataLoaded" :loading="true" />
            <div v-else>
                <TableOverview v-if="dataLoaded"
                    kind="module"
                    :page-title="labels.search"
                    :searchable="true"
                    :settings="settings"
                    :footer-buttons="[]"
                    :get-page="getModulesMt"
                    :items="items"
                />
            </div>
        </template>

        <template v-slot:footer>
            <div class="controls">
                <button @click="close" class="btn btn-danger">
                    {{ labels.close }}
                </button>
            </div>
        </template>
    </Popup>
</template>

<script>
    const colors = require("/colors.config.json");

    import { mapActions, mapGetters, mapMutations } from "vuex";

    export default {
        name: "ModulesSelector",
        components: {
            Popup: () => import('@/components/Popups/Popup'),
            SvgLoader: () => import('@/components/Preloaders/SvgLoader'),
            TableOverview: () => import('@/views/Dashboard/Common/TableOverview.vue')
        },
        props: {
            extraFieldsToEmit: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                selectedModule: '',
                selectedMbundle: '',
                showPopup: true,
                dataLoaded: false,
                items: {}
            };
        },
        mounted() {
            this.getModulesMt(1);
        },
        methods: {
            ...mapActions('notification', ['addNotification']),
            ...mapActions('_module', ['getList']),
            ...mapMutations('helper', ['setTablePagination']),
            confirmSelection() {
                this.showPopup = false;
                setTimeout(() => {
                    if (Object.keys(this.extraFieldsToEmit).length) {
                        this.$emit('module-selected', this.selectedModule, this.selectedMbundle, this.extraFieldsToEmit);
                    } else {
                        this.$emit('module-selected', this.selectedModule, this.selectedMbundle);
                    }
                }, 500);
            },
            close() {
                this.showPopup = false;
                setTimeout(() => {
                    this.$emit('module-selelector-close');
                }, 500);
            },
            getModulesMt(page) {
                let data = { page };

                this.getList(data).then((res) => {
                    if (res.success) {
                        this.items = res.data.items
                        this.setTablePagination(res.data.pages);
                        this.dataLoaded = true
                    } else if (res.error) {
                        this.addNotification({
                            variant: 'danger',
                            msg: res.error,
                            labels: this.labels,
                        });
                    }
                }).catch((err) => console.log(err));
            },
        },
        computed: {
            ...mapGetters("helper", ["labels"]),
            settings() {
                let result = {
                    header: [
                        {
                            headerLabel: "Name",
                            headerColor: `${colors.gray_text}`,
                            key: "title",
                            computeFn: (input) => input,
                            bodyColor: `${colors.dark}`,
                            columnWidth: "1 1 30%",
                            whiteSpace: "nowrap",
                        }
                    ],
                    sortable: false,
                    minTableWidth: "300px",
                    actionsWidth: "0 0 15%",
                    actionsHeaderLabel: "",
                    actions: [
                        {
                            label: this.labels.select,
                            variant: "btn-purple",
                            styles: { padding: "8px 15px", fontWeight: "700" },
                            action: (data) => {
                                this.selectedModule = data._id;
                                this.selectedMbundle = data.monetizationBundle;
                                this.confirmSelection();
                            }
                        }
                    ],
                };

                return result;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .controls {
        margin: 0 -7.5px;

        > button {
            margin: 0 7.5px;

            &.btn-danger {
                background-color: #F66868 !important;
            }

            &.btn-success {
                background-color: #00b894 !important;
            }
        }
    }
</style>
